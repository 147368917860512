<template>
  <footer class="relative bg-slate-200 pt-8 pb-6">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0);"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-slate-200 fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center lg:text-left">
        <div class="w-full lg:w-6/12 px-4">
          <h4 class="text-3xl font-semibold">Let's keep in touch!</h4>
          <h5 class="text-lg mt-0 mb-2 text-slate-600">
            Find us on social media, we typically respond in 1 business day.
          </h5>
          <div class="mt-6 lg:mb-0 mb-6">
            <button
              class="bg-white text-sky-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-linkedin"></i>
            </button>
            <button
              class="bg-white text-sky-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-twitter-square"></i>
            </button>
            
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 px-4 ml-auto">
              <span
                class="block uppercase text-slate-500 text-sm font-semibold mb-2"
              >
                Useful Links
              </span>
              <ul class="list-unstyled">
                <li>
                  <a
                    class="text-slate-600 hover:text-slate-800 font-semibold block pb-2 text-sm"
                    href="https://www.creative-tim.com/presentation?ref=vn-footer"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    class="text-slate-600 hover:text-slate-800 font-semibold block pb-2 text-sm"
                    href="https://blog.creative-tim.com?ref=vn-footer"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    class="text-slate-600 hover:text-slate-800 font-semibold block pb-2 text-sm"
                    href="https://www.creative-tim.com/bootstrap-themes/free?ref=vn-footer"
                  >
                    Free resources
                  </a>
                </li>
              </ul>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <span
                class="block uppercase text-slate-500 text-sm font-semibold mb-2"
              >
                Other Resources
              </span>
              <ul class="list-unstyled">
                <li>
                  <a
                    class="text-slate-600 hover:text-slate-800 font-semibold block pb-2 text-sm"
                    href="#"
                  >
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a
                    class="text-slate-600 hover:text-slate-800 font-semibold block pb-2 text-sm"
                    href="#"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    class="text-slate-600 hover:text-slate-800 font-semibold block pb-2 text-sm"
                    href="#"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-6 border-slate-300" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full container md:w-4/12 px-6 mx-auto text-center">
          <div class="text-sm text-slate-500 font-semibold py-1 flex">
            Copyright © DigitalScot.co.uk {{ date }}
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 580.000000 386.000000" preserveAspectRatio="xMidYMid meet" class="w-10 align-top">
<g transform="translate(0.000000,386.000000) scale(0.100000,-0.100000)" fill="#005EB8" stroke="none">
<path d="M2025 3095 c-38 -7 -96 -22 -128 -33 -78 -28 -187 -90 -187 -106 0 -8 33 -33 73 -57 39 -23 135 -82 212 -129 182 -113 445 -272 565 -343 52 -31 117 -70 145 -86 62 -38 80 -38 136 -3 24 16 118 73 209 127 91 54 194 117 230 140 36 23 117 72 180 110 101 60 176 105 337 204 24 14 41 31 40 36 -6 18 -80 64 -152 94 -235 99 -491 74 -701 -70 -53 -36 -135 -114 -163 -156 -24 -35 -63 -45 -78 -19 -13 22 -113 127 -113 119 0 -4 -5 0 -11 9 -11 21 -104 85 -167 117 -109 56 -277 74 -427 46z"/>
<path d="M1461 2588 c-67 -206 -16 -498 127 -733 10 -16 32 -55 50 -85 18 -30 40 -66 50 -78 17 -23 20 -23 53 -9 19 8 106 58 194 112 88 54 219 133 290 176 190 114 178 101 119 134 -27 16 -179 108 -339 205 -420 254 -494 298 -515 305 -14 4 -21 -2 -29 -27z"/>
<path d="M4004 2587 c-28 -19 -57 -36 -66 -39 -8 -4 -80 -46 -159 -94 -79 -48 -166 -100 -194 -116 -27 -16 -69 -41 -92 -56 -23 -15 -105 -64 -182 -110 -78 -46 -143 -87 -146 -92 -8 -12 626 -400 659 -403 14 -1 35 19 73 73 28 41 52 78 53 82 0 4 13 29 29 55 42 70 97 216 116 308 33 162 12 425 -34 425 -4 0 -29 -15 -57 -33z"/>
<path d="M2665 1783 c-49 -31 -137 -84 -195 -118 -215 -127 -475 -286 -488 -299 -10 -10 1 -25 55 -79 136 -133 403 -335 573 -432 72 -41 95 -49 150 -53 61 -4 70 -1 145 38 137 72 299 185 462 322 145 122 205 181 198 198 -3 8 -40 35 -82 60 -42 25 -104 62 -137 82 -34 20 -86 51 -116 69 -30 17 -142 85 -248 150 -106 66 -200 119 -210 118 -9 0 -57 -25 -107 -56z"/>
</g>
</svg>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>
